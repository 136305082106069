import { reactive, ref, watch } from "vue";
import type { UnitOfWeight } from "@/graphql/graphql";
import type { FoodType } from "@/lib/constants/FoodType";
import { msalInstance } from "@/lib/services/msal/instance";
import type { SolveFormulationQuery } from "@/store/calculation/actions";
import type { Calculation, Formulation } from "@/types/calculation";

export type SavedResult = {
	id: string;
	unitUsed: UnitOfWeight;
	results: ResultOf<typeof SolveFormulationQuery>["CNMIMCalculateMoldGrowth"];
	region?: string;
	foodType?: FoodType;
} & Calculation & {
		formulations: Formulation[];
	};

const LS_NAME = "CNMIM_CALCULATION";

const calculating = ref<boolean>(false);
const validating = ref({
	validated: false,
	flourWeightMissing: false,
	totalBatchWeightMissing: false,
	bakeLossMissing: false,
});
const id = ref<string | undefined>();
const result = ref<{
	formulationResult: ResultOf<typeof SolveFormulationQuery>["CNMIMCalculateMoldGrowth"];
	formulationNames: string[];
}>({
	formulationResult: [],
	formulationNames: [],
});
export const savedResults = ref<SavedResult[]>([]);
export const currentResult = ref<string>();

export const calculation = reactive({
	result,
	calculating,
	validating,
	id,
	savedResults,
	currentResult,
});

export function initializeCalculationState() {
	const accountId = msalInstance.getActiveAccount();

	if (!accountId) {
		return;
	}

	const ls = localStorage.getItem(`${accountId.homeAccountId}_${LS_NAME}`);

	if (!ls) {
		return;
	}

	const data = JSON.parse(ls) as any;

	if (data.data) {
		const hasOldControlFormulation = data.data.formulations.find(
			(formulation: any) => formulation.id === "control",
		);

		if (hasOldControlFormulation) {
			// TODO

			// Don't load old control formulation. It's not needed anymore.
			// Implemented on 14-09-2023.
			// Can be removed in future.

			// Don't set any date at all.
			return;
		}
	}

	if (data.savedResults) {
		savedResults.value = data.savedResults;
	}
}

initializeCalculationState();

watch(calculation, (current) => {
	const accountId = msalInstance.getActiveAccount();

	if (!accountId) {
		return;
	}

	localStorage.setItem(`${accountId.homeAccountId}_${LS_NAME}`, JSON.stringify(current));
});
